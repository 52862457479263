import { useEffect, useState } from "react";
import Layout from "../layout.tsx/app";
import { getData } from "../../api/get-data";
import { HiOutlineSearch, HiTrash, HiX } from "react-icons/hi";
import { Spinner } from "flowbite-react";
import AddButton from "../../components/buttons/add";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../components/buttons";
import ModalDeleteConfirmation from "../../components/modal/delete-confirmation";
import BaseModal from "../../components/modal/base";
import Pagination from "../../components/tables/pagination";
import Table from "../../components/tables/base";
import { FormInput, FormInputPassword } from "../../components/forms/input";
import {
  FormSelect,
  FormSelectTimezone,
} from "../../components/forms/input-select";
import { SelectOptionType } from "../../types/form";
import { CertificateType } from "../../types/certificate";
import { request } from "../../api/config";
import { Key, Pencil, Trash } from "@phosphor-icons/react";
import { useAlert } from "../../stores/alert";
import moment from "moment";
import { useCertificates } from "../../stores/certificate";

type FormValues = {
  user_id: string;
  title: string;
  webinar_title: string;
  price: string;
  media_service: string;
  use_points: string;
  use_voucher: string;
  use_balance: string;
  is_uploaded: number;
  is_paid: number;
  webinar_date: string;
  certificate_path: string | null;
};

type ErrorForm = {
  is_uploaded: [] | null;
  certificate_path: [] | null;
};

const IndexCertificate = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [q, setQ] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<"create" | "edit" | undefined>(
    undefined
  );
  const [modalReset, setModalReset] = useState<boolean>(false);
  const [randomString, setRandomString] = useState<string | null>(null);
  const [errors, setErrors] = useState<ErrorForm | null>(null);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<CertificateType | null>(null);

  const forms = useForm<FormValues>({
    defaultValues: {
      is_uploaded: 0,
      certificate_path: null,
    },
  });

  const { setCertificates, GetCertificates } = useCertificates();
  const { setMessage } = useAlert();

  const certificatePath = forms.watch("certificate_path");

  useEffect(() => {
    forms.setValue("is_uploaded", certificatePath ? 1 : 0);
  }, [certificatePath, forms]);

  const getCertificate = async (
    search?: string,
    searchMode: boolean = false
  ) => {
    setLoading(true);
    try {
      const data = await getData("/certificate", page, search, searchMode);
      return data;
    } catch {}
  };

  const handleSearch = async (input: string | undefined) => {
    setQ(input);
    const data = await getCertificate(input ?? "", true);
    setCertificates(data);
    setLoading(false);
  };

  const handleNext = () => {
    if (page === GetCertificates?.last_page) {
      return;
    }

    setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page === 1) {
      return;
    }

    setPage(page - 1);
  };

  const handleSave = forms.handleSubmit(async (data) => {
    setLoadingSubmit(true);
    try {
      let payload = {
        ...data,
      };
      if (modalMode === "create") {
        await request.post("/certificate/create", payload);
      } else {
        await request.put(`/certificate/${selected?.id}`, payload);
      }
      setModalAdd(false);
      setModalMode(undefined);
      setMessage("Certificate saved!", "success");
    } catch (err: any) {
      if (err.response && err.response.data.errors) {
        setErrors(err.response.data.errors); // Update state with errors
      }
      setMessage(err.response.data.message, "error");
    }
    setErrors(null);
    setLoadingSubmit(false);
  });

  const handleFormEdit = (item: CertificateType) => {
    setSelected(item);
    setModalMode("edit");
    forms.setValue("is_uploaded", item.is_uploaded ?? 0);
    forms.setValue("certificate_path", item.certificate_path ?? "");
    setModalAdd(true);
  };

  const handleDelete = async () => {
    setLoadingSubmit(true);
    try {
      await request.delete(`/certificate/${selected?.id}`);
      setSelected(null);
      setModalDelete(false);
      setMessage("Certificate deleted", "success");
    } catch (err: any) {
      setErrors(err.response.data.errors);
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    Promise.all([getCertificate()]).then((res) => {
      setCertificates(res[0]);
      setLoading(false);
    });
  }, [page, loadingSubmit]);

  return (
    <Layout
      withPageTitle
      title="Certificate Management"
      pageTitleContent={
        <div className="flex items-center">
          <input
            type="text"
            className="rounded-l-lg border-gray-300"
            placeholder={"Cari disini..."}
            onChange={(e) => setQ(e.target.value)}
            disabled={loading}
            value={q}
          />
          {q && (
            <button
              onClick={() => handleSearch("")}
              className="py-3 px-2 border border-red-600 bg-red-600 text-white"
            >
              <HiX />
            </button>
          )}
          <button
            className={`${loading ? "py-2 px-3" : "p-3"} text-lg rounded-r-lg ${
              loading
                ? "bg-blue-500 text-white cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
            disabled={loading}
            onClick={() => handleSearch(q ?? "")}
          >
            {loading ? <Spinner size={"sm"} /> : <HiOutlineSearch />}
          </button>
        </div>
      }
    >
      <AddButton
        onClick={() => {
          setModalAdd(true);
          setModalMode("create");
          forms.reset();
        }}
      />
      <Table>
        <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-blue-100 dark:bg-blue-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3">#</th>
              <th className="px-6 py-3">User ID</th>
              <th className="px-6 py-3">Webinar Title</th>
              <th className="px-6 py-3">Price</th>
              <th className="px-6 py-3">Media Service</th>
              <th className="px-6 py-3">Webinar Date</th>
              <th className="px-6 py-3">Certificate Path</th>
              <th className="px-6 py-3 text-center">Opsi</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={8} className="text-center py-3">
                  Loading...
                </td>
              </tr>
            ) : (
              <>
                {GetCertificates?.data.length === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center py-3">
                      Tidak ada data ditemukan!
                    </td>
                  </tr>
                ) : (
                  <>
                    {GetCertificates?.data.map((item, key) => (
                      <tr
                        key={key}
                        className="hover:bg-blue-100 dark:hover:bg-gray-800 transition-all"
                      >
                        <td className="px-6 py-3">
                          {(
                            key +
                            1 +
                            GetCertificates.per_page *
                              (GetCertificates.current_page - 1)
                          ).toString()}
                        </td>
                        <td className="px-6 py-3">{item.user_id ?? ""}</td>
                        <td className="px-6 py-3">
                          {item.webinar_title ?? ""}
                        </td>
                        <td className="px-6 py-3">{item.price ?? ""}</td>
                        <td className="px-6 py-3">
                          {item.media_service ?? ""}
                        </td>
                        <td className="px-6 py-3">{item.webinar_date ?? ""}</td>
                        <td className="px-6 py-3">
                          {item.certificate_path ?? ""}
                        </td>
                        <td className="px-6 py-3 text-center">
                          <div className="flex justify-center gap-4">
                            {/* Edit Button */}
                            <button
                              className="text-blue-600 hover:text-blue-800 transition duration-300"
                              onClick={() => handleFormEdit(item)}
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </Table>

      <Pagination
        currentPage={GetCertificates?.current_page ?? 1}
        totalPage={GetCertificates?.last_page ?? 1}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />

      <BaseModal
        title={
          modalMode === "create" ? "Tambah Certificate" : "Edit Certificate"
        }
        isOpen={modalAdd}
        close={() => setModalAdd(false)}
      >
        <FormProvider {...forms}>
          <form>
            <FormInput
              name="certificate_path"
              control={forms.control}
              label="Certificate Path"
              error={errors?.certificate_path}
            />
            <div className="mt-3 flex items-center justify-end">
              <Button className="px-8" onClick={handleSave}>
                {loadingSubmit ? <Spinner /> : "Simpan"}
              </Button>
            </div>
          </form>
        </FormProvider>
      </BaseModal>

      <ModalDeleteConfirmation
        isOpen={modalDelete}
        close={() => setModalDelete(false)}
        subTitle="Title"
        name={selected?.title ?? ""}
        loading={loadingSubmit}
        action={handleDelete}
      />
    </Layout>
  );
};

export default IndexCertificate;
