import { useEffect, useState, ChangeEvent, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../layout.tsx/app";
import { Spinner } from "flowbite-react";
import { useForm } from "react-hook-form";
import { useUserPsikolog } from "../../stores/psikolog";
import { FormInput } from "../../components/forms/input";
import { useAlert } from "../../stores/alert";
import { request } from "../../api/config";
import { getData } from "../../api/get-data";
import LoadingPage from "../layout.tsx/loading";
import { Button } from "../../components/buttons";

type FormValues = {
    id: string;
    fullname: string;
    email: string;
    phone: string;
    avatar: string;
    timezone: string;
    created_at: string;
    current_balance: number;
    city: {
        id: string | null;
        name: string | null;
    };
    postal_code: string | null;
    birthPlace: string | null;
    birth_date: string | null;
    balance: number;
    rating: {
        rate: number;
        total: number;
    };
    gender: string | null;
    is_active: number;
    approved_at: string;
    approved_by: {
        id: string;
        name: string;
    };
    email_verified_at: string;
    status: string;
    activeMembership: any[];
    address: string;
    specialists: any[];
    expertises: any[];
    year_experience: number | null;
    documents: {
        nomor: string;
        type: number;
        document_type: {
            id: number;
            name: string;
            alias: string;
        };
        path: string;
        url: string;
        label: string;
        created_at: string;
        updated_at: string;
    }[];
};

type ErrorForm = {
    id?: string[];
    fullname?: string[];
    email?: string[];
    phone?: string[];
    avatar?: string[];
    timezone?: string[];
    created_at?: string[];
    current_balance?: string[];
    city?: {
        id?: string[];
        name?: string[];
    };
    postal_code?: string[];
    birthPlace?: string[];
    birth_date?: string[];
    balance?: string[];
    rating?: {
        rate?: string[];
        total?: string[];
    };
    gender?: string[];
    is_active?: string[];
    approved_at?: string[];
    approved_by?: {
        id?: string[];
        name?: string[];
    };
    email_verified_at?: string[];
    status?: string[];
    activeMembership?: string[];
    address?: string[];
    specialists?: string[];
    expertises?: string[];
    year_experience?: string[];
    documents?: {
        nomor?: string[];
        type?: string[];
        document_type?: {
            id?: string[];
            name?: string[];
            alias?: string[];
        };
        path?: string[];
        url?: string[];
        label?: string[];
        created_at?: string[];
        updated_at?: string[];
    }[];
};



const DetailPsikolog = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [existingImage, setExistingImage] = useState<string | null>(null);
    const [errors, setErrors] = useState<ErrorForm | null>(null);
    const { id } = useParams();
    const { detail, setDetail, setUserPsikologs, userPsikologs } = useUserPsikolog();
    const { setMessage } = useAlert();
    const { setValue, reset, handleSubmit, control } = useForm<FormValues>();
    const [previewSrc, setPreviewSrc] = useState<string | null>(null);
    const uploadInputRef = useRef<HTMLInputElement | null>(null);
    const imagePreviewRef = useRef<HTMLDivElement | null>(null);
    const [fileName, setFileName] = useState<string>("");
    const [editorContent, setEditorContent] = useState("");
    const navigate = useNavigate();


    const getDetail = async () => {
        setLoading(true);
        try {
            const data = await getData(`/psikolog/${id}/detail`);
            return data;
        } catch (err: any) {
            console.log(err);
        }
    };

    const handleApprove = async (id: string) => {
        setLoading(true); // Mulai loading sebelum proses approve
        try {
            await request.patch(`/psikolog/${id}/approve`);
            const updatedData = await getDetail();
            setUserPsikologs(updatedData);
            setMessage("Psikolog berhasil diapprove", "success");
        } catch (error) {
            console.error("Error approving psikolog:", error);
            setMessage("Gagal melakukan approve psikolog", "error");
        } finally {
            setLoading(false); // Hentikan loading setelah proses selesai
        }
    };

    const handleUnapprove = async (id: string) => {
        setLoading(true); // Mulai loading sebelum proses unapprove
        try {
            await request.patch(`/psikolog/${id}/unapprove`);
            const updatedData = await getDetail();
            setUserPsikologs(updatedData);
            setMessage("Psikolog berhasil diunapprove", "success");
        } catch (error) {
            console.error("Error unapproving psikolog:", error);
            setMessage("Gagal melakukan unapprove psikolog", "error");
        } finally {
            setLoading(false); // Hentikan loading setelah proses selesai
        }
    };

    useEffect(() => {
        if (id) {
            setLoading(true);
            getDetail().then((res) => {
                setDetail(res);
                console.log(res);
                setValue("fullname", res.fullname);
                setValue("email", res.email);
                setValue("phone", res.phone);
                setValue("current_balance", res.current_balance);
                setValue("city", res.city);
                setValue("postal_code", res.postal_code);
                setValue("birthPlace", res.birthPlace);
                setValue("birth_date", res.birth_date);
                setValue("balance", res.balance);
                setValue("rating", res.rating);
                setValue("gender", res.gender);
                setValue("is_active", res.is_active);
                setValue("approved_at", res.approved_at);
                setValue("approved_by", res.approved_by);
                setValue("email_verified_at", res.email_verified_at);
                setValue("status", res.status);
                setValue("activeMembership", res.activeMembership);
                setValue("address", res.address);
                setValue("specialists", res.specialists);
                setValue("expertises", res.expertises);
                setValue("year_experience", res.year_experience);
                setValue("documents", res.documents);
                if (res.avatar) {
                    setExistingImage(res.avatar);
                    setPreviewSrc(res.avatar);
                } else {
                    setExistingImage(null);
                }
                setLoading(false);
            });
        } else {
            setDetail(null);
            reset({

            });
            setEditorContent("");
        }
    }, [id]);

    return (
        <Layout
            withPageTitle
            title={id ? 'Detail Psikolog' : 'Create Psikolog'}
            pageTitleContent={<></>}
        >
            <>
                {loading ? (
                    <LoadingPage />
                ) : (
                    <form>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-8 md:mb-0">

                                <FormInput
                                    name="fullname"
                                    control={control}
                                    label="Fullname"
                                    error={errors?.fullname}
                                    disabled={true}
                                />
                                <FormInput
                                    name="email"
                                    control={control}
                                    label="Email"
                                    error={errors?.email}
                                    disabled={true}
                                />
                                <FormInput
                                    name="phone"
                                    type="phone"
                                    control={control}
                                    label="Phone"
                                    error={errors?.phone}
                                    disabled={true}
                                />
                                <FormInput
                                    name="current_balance"
                                    type="text"
                                    control={control}
                                    label="Current Balance"
                                    error={errors?.current_balance}
                                    disabled={true}
                                />
                                <FormInput
                                    name="city"
                                    type="text"
                                    control={control}
                                    label="City"
                                    error={errors?.city}
                                    disabled={true}
                                />
                                <FormInput
                                    name="postal_code"
                                    type="text"
                                    control={control}
                                    label="Postal Code"
                                    error={errors?.postal_code}
                                    disabled={true}
                                />
                                <FormInput
                                    name="birthPlace"
                                    type="text"
                                    control={control}
                                    label="Birth Place"
                                    error={errors?.birthPlace}
                                    disabled={true}
                                />
                                <FormInput
                                    name="birth_date"
                                    type="text"
                                    control={control}
                                    label="Birth Date"
                                    error={errors?.birth_date}
                                    disabled={true}
                                />
                                <FormInput
                                    name="balance"
                                    type="text"
                                    control={control}
                                    label="Balance"
                                    error={errors?.balance}
                                    disabled={true}
                                />
                                <FormInput
                                    name="rating"
                                    type="text"
                                    control={control}
                                    label="Rating"
                                    error={errors?.rating}
                                    disabled={true}
                                />
                                <FormInput
                                    name="gender"
                                    type="text"
                                    control={control}
                                    label="Gender"
                                    error={errors?.gender}
                                    disabled={true}
                                />
                                <FormInput
                                    name="status"
                                    type="text"
                                    control={control}
                                    label="Status"
                                    error={errors?.status}
                                    disabled={true}
                                />
                                <FormInput
                                    name="activeMembership"
                                    type="text"
                                    control={control}
                                    label="Active Membership"
                                    error={errors?.activeMembership}
                                    disabled={true}
                                />
                                <FormInput
                                    name="address"
                                    type="text"
                                    control={control}
                                    label="Address"
                                    error={errors?.address}
                                    disabled={true}
                                />
                                <FormInput
                                    name="specialists"
                                    type="text"
                                    control={control}
                                    label="Specialists"
                                    error={errors?.specialists}
                                    disabled={true}
                                />
                                <FormInput
                                    name="expertises"
                                    type="text"
                                    control={control}
                                    label="Expertises"
                                    error={errors?.expertises}
                                    disabled={true}
                                />
                                <FormInput
                                    name="year_experience"
                                    type="text"
                                    control={control}
                                    label="Year Experience"
                                    error={errors?.year_experience}
                                    disabled={true}
                                />
                            </div>
                            <div className="-full md:w-1/2 px-3">
                                <div className="max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden items-center">
                                    {/* Documents Section */}
                                    <div className="max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden items-center">
                                        <div className="px-4 py-6">
                                            {/* Menampilkan gambar dokument */}
                                            {detail?.documents && detail.documents.length > 0 && (
                                                <div className="space-y-4">
                                                    {detail.documents.map((doc, index) => (
                                                        <div key={index} className="bg-gray-100 p-4 rounded-lg">
                                                            <h3 className="text-lg font-semibold">{doc.label}</h3>
                                                            <div className="text-sm text-gray-600">
                                                                {doc.document_type.name}:
                                                                <a href={doc.url} target="_blank" className="text-blue-500 hover:underline">
                                                                    Lihat Dokumen
                                                                </a>
                                                            </div>
                                                            {doc.document_type.name === 'Foto Diri' && (
                                                                <img
                                                                    src={doc.url}
                                                                    alt="Foto Diri"
                                                                    className="max-w-xs mx-auto mt-4 rounded-lg"
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 flex items-center justify-end">
                        {detail?.status === "verified" ? (
                            <Button
                              onClick={() => handleUnapprove(detail.id)}
                              className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded-md"
                            >
                              Unapprove
                            </Button>
                          ) : detail?.status === "moderation" ? (
                            <Button
                              onClick={() => handleApprove(detail?.id)}
                              className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded-md"
                            >
                              Approve
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                    </form>
                )}
            </>
        </Layout>
    );
};

export default DetailPsikolog;
