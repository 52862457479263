import { HiChartPie, HiUserGroup, HiClock } from "react-icons/hi";

import { MdOutlineDashboard } from "react-icons/md";

import { FiMessageSquare, FiFolder, FiShoppingCart } from "react-icons/fi";

type Menu = {
  label: string;
  icon: any | null;
  href: string | null;
  name: string | null;
  notif?: number | string;
  child?: {
    label: string;
    name: string;
    href: string;
    notif?: number | string;
  }[];
};

const dashboardMenu = {
  label: "Dasbor",
  name: "Dashboard",
  icon: MdOutlineDashboard,
  href: "/",
};

const ManagementPsikolog = {
  label: "Psikolog",
  name: "Psikolog",
  icon: FiFolder,
  href: "/psikolog",
};

const ManagementVoucher = {
  label: "Voucher",
  name: "Voucher",
  icon: FiFolder,
  href: "/voucher",
};

const ProductMenu = {
  label: "Counseling Service",
  name: "Counseling Service",
  icon: FiFolder,
  href: "#",
  child: [
    { label: "Counselings", name: "Counselings", href: "/product/counselings" },
    {
      label: "Counseling Products",
      name: "CounselingProduct",
      href: "/product/counseling-products",
    },
    { label: "price", name: "price", href: "/product/price" },
  ],
};

const MediaMenu = {
  label: "Media",
  name: "Media",
  icon: FiFolder,
  href: "#",
  child: [
    { label: "Category", name: "Category", href: "/media/category" },
    {
      label: "Sub Category",
      name: "Sub Category",
      href: "/media/sub-category",
    },
    { label: "Webinar", name: "Webinar", href: "/media/webinar" },
    { label: "Youtube", name: "Youtube", href: "/media/youtube" },
  ],
};

const MediaMenuWriter = {
  label: "Media",
  name: "Media",
  icon: FiFolder,
  href: "#",
  child: [
    { label: "Category", name: "Category", href: "/media/category" },
    {
      label: "Sub Category",
      name: "Sub Category",
      href: "/media/sub-category",
    },
  ],
};

const ManagementArtikel = {
  label: "Artikel",
  name: "Artikel",
  icon: FiFolder,
  href: "/media/artikel",
};

const ManagementCertificate = {
  label: "Certificate",
  name: "Certificate",
  icon: FiFolder,
  href: "/certificate",
};

const AssessmentMenu = {
  label: "Assessment Service",
  name: "Assessment Service",
  icon: FiFolder,
  href: "#",
  child: [
    { label: "Group", name: "Group", href: "/assestment/group" },
    { label: "Product", name: "Product", href: "/assestment/product" },
  ],
};

export const menuUser: Menu[] = [
  dashboardMenu,
  ManagementPsikolog,
  ManagementVoucher,
  ProductMenu,
  MediaMenu,
  ManagementCertificate,
  AssessmentMenu,
];

export const menuWriter: Menu[] = [
  dashboardMenu,
  MediaMenuWriter,
  ManagementArtikel,
];

export const menuAdmin: Menu[] = [
  dashboardMenu,
  ManagementPsikolog,
  ManagementVoucher,
  ProductMenu,
  MediaMenu,
  ManagementCertificate,
  AssessmentMenu,
];
