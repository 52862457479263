import { useEffect, useState } from "react";
// import { useUserAdmin } from "../../../stores/users/admins";
import Layout from "../layout.tsx/app";
import { getData } from "../../api/get-data";
import { HiOutlineSearch, HiTrash, HiX } from "react-icons/hi";
import { Spinner } from "flowbite-react";
// import AddButton from "../../components/buttons/add";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../components/buttons";
import ModalDeleteConfirmation from "../../components/modal/delete-confirmation";
import BaseModal from "../../components/modal/base";
import Pagination from "../../components/tables/pagination";
import Table from "../../components/tables/base";
import { FormInput, FormInputPassword } from "../../components/forms/input";
import {
  FormSelect,
  FormSelectTimezone,
} from "../../components/forms/input-select";
import { SelectOptionType } from "../../types/form";
import { UserPsikologType } from "../../types/users";
import { request } from "../../api/config";
// import { Key, Eye, Trash } from "@phosphor-icons/react";
import { useAlert } from "../../stores/alert";
import moment from "moment";
import { useUserPsikolog } from "../../stores/psikolog";
import { useNavigate } from "react-router-dom";

type FormValues = {
  name: string;
  email: string;
  timezone: SelectOptionType | undefined;
  role: SelectOptionType | undefined;
  password: string;
};

type ErrorForm = {
  name: [] | null;
  email: [] | null;
  timezone: [] | null;
  role: [] | null;
  password: [] | null;
};

const UserPsikolog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [q, setQ] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [modalMode, setModalMode] = useState<"create" | "edit" | undefined>(
    undefined
  );
  const [errors, setErrors] = useState<ErrorForm | null>(null);
  const [selected, setSelected] = useState<UserPsikologType | null>(null);
  const navigate = useNavigate();

  const forms = useForm<FormValues>({
    defaultValues: {
      timezone: { label: moment.tz.guess(), value: moment.tz.guess() },
    },
  });
  const { setUserPsikologs, userPsikologs } = useUserPsikolog();
  const { setMessage } = useAlert();

  const getUserPsikolog = async (
    search?: string,
    searchMode: boolean = false
  ) => {
    setLoading(true);
    try {
      const url =
        searchMode && search
          ? `/psikolog/?type=psikolog&name=${search}`
          : `/psikolog/?type=psikolog`;
      const data = await getData(url, page);
      setLoading(false);
      return data;
    } catch (error) {
      console.error("Error fetching psikolog:", error);
      setLoading(false);
      return null;
    }
  };

  const handleSearch = async (input: string | undefined) => {
    setLoading(true);
    try {
      const data = await getUserPsikolog(input ?? "", true);
      setUserPsikologs(data);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (page === userPsikologs?.last_page) {
      return;
    }

    setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page === 1) {
      return;
    }

    setPage(page - 1);
  };

  useEffect(() => {
    Promise.all([getUserPsikolog()]).then((res) => {
      setUserPsikologs(res[0]);
      setLoading(false);
    });
  }, [page, loadingSubmit]);

  return (
    <Layout
      withPageTitle
      title="Psikolog Management"
      pageTitleContent={
        <div className="flex items-center">
          <input
            type="text"
            className="rounded-l-lg border-gray-300"
            placeholder={"Cari disini..."}
            onChange={(e) => setQ(e.target.value)}
            disabled={loading}
            value={q}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(q);
              }
            }}
          />

          {q && (
            <button
              onClick={() => {
                setQ(""); // Reset pencarian
                handleSearch(""); // Jalankan pencarian dengan query kosong
              }}
              className="py-3 px-2 border border-red-600 bg-red-600 text-white"
            >
              <HiX />
            </button>
          )}

          <button
            className={`${loading ? "py-2 px-3" : "p-3"} text-lg rounded-r-lg ${
              loading
                ? "bg-blue-500 text-white cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
            disabled={loading || !q} // Disable jika tidak ada query pencarian
            onClick={() => q && handleSearch(q)}
          >
            {loading ? <Spinner size={"sm"} /> : <HiOutlineSearch />}
          </button>
        </div>
      }
    >
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-blue-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 border-b">
                No
              </th>
              <th scope="col" className="px-6 py-3 border-b">
                Nama
              </th>
              <th scope="col" className="px-6 py-3 border-b">
                Email
              </th>
              <th scope="col" className="px-6 py-3 border-b text-center">
                Status
              </th>
              <th scope="col" className="px-6 py-3 border-b text-center">
                Aksi
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Data */}
            {userPsikologs?.data.length === 0 ? (
              <tr>
                <td colSpan={5} className="text-center py-3">
                  Tidak ada data ditemukan!
                </td>
              </tr>
            ) : (
              <>
                {userPsikologs?.data.map((item, index) => (
                  <tr
                    key={item.id}
                    className="bg-white border-b hover:bg-blue-100 dark:hover:bg-blue-600"
                  >
                    <td className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4">
                      {item.fullname ?? "Nama tidak tersedia"}
                    </td>
                    <td className="px-6 py-4">
                      {item.email ?? "Email tidak tersedia"}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {item.status === "verified" ? (
                        <span className="text-xs py-1 px-3 rounded text-green-50 bg-green-600">
                          Verified
                        </span>
                      ) : item.status === "moderation" ? (
                        <span className="text-xs py-1 px-3 rounded text-white bg-yellow-500">
                          Moderation
                        </span>
                      ) : (
                        <span className="text-xs py-1 px-3 rounded text-white bg-gray-500">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-center">
                      <button
                        onClick={() =>
                          window.open(`/psikolog/${item.id}`, "_blank")
                        }
                        className="text-blue-600 hover:text-blue-800 transition duration-300"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={userPsikologs?.current_page ?? 1}
        totalPage={userPsikologs?.last_page ?? 1}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </Layout>
  );
};

export default UserPsikolog;
