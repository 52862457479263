import { useEffect, useState } from "react";
import Layout from "../../layout.tsx/app";
import { getData } from "../../../api/get-data";
import { HiOutlineSearch, HiX } from "react-icons/hi";
import { Spinner } from "flowbite-react";
import AddButton from "../../../components/buttons/add";
import ModalDeleteConfirmation from "../../../components/modal/delete-confirmation";
import Pagination from "../../../components/tables/pagination";
import Table from "../../../components/tables/base";
import { WebinarType } from "../../../types/webinar";
import { request } from "../../../api/config";
import { Pencil, Trash } from "@phosphor-icons/react";
import { useAlert } from "../../../stores/alert";
import { useNavigate } from "react-router-dom";
import { useWebinar } from "../../../stores/webinar";

const Webinar = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [q, setQ] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<WebinarType | null>(null);
  const { setWebinars, webinars } = useWebinar();
  const { setMessage } = useAlert();
  const navigate = useNavigate();

  const getWebinar = async (search?: string, searchMode: boolean = false) => {
    setLoading(true);
    try {
      const data = await getData("/webinar", page, search, searchMode);
      return data;
    } catch {}
  };

  const handleSearch = async (input: string | undefined) => {
    setQ(input);
    const data = await getWebinar(input ?? "", true);
    setWebinars(data);
    setLoading(false);
  };

  const handleNext = () => {
    if (page === webinars?.last_page) {
      return;
    }

    setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page === 1) {
      return;
    }

    setPage(page - 1);
  };

  const handleDelete = async () => {
    setLoadingSubmit(true);
    try {
      await request.delete(`/webinar/${selected?.id}`);
      setSelected(null);
      setModalDelete(false);
      setMessage("Webinar deleted", "success");
    } catch (err: any) {
      setMessage(err.response.data.errors, "error");
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    Promise.all([getWebinar()]).then((res) => {
      setWebinars(res[0]);
      setLoading(false);
    });
  }, [page, loadingSubmit]);

  return (
    <Layout
      withPageTitle
      title="Webinar Management"
      // pageTitleContent={
      //   <div className="flex items-center">
      //     <input
      //       type="text"
      //       className="rounded-l-lg border-gray-300"
      //       placeholder={"Cari disini..."}
      //       onChange={(e) => setQ(e.target.value)}
      //       disabled={loading}
      //       value={q}
      //     />
      //     {q && (
      //       <button
      //         onClick={() => handleSearch("")}
      //         className="py-3 px-2 border border-red-600 bg-red-600 text-white"
      //       >
      //         <HiX />
      //       </button>
      //     )}
      //     <button
      //       className={`${loading ? "py-2 px-3" : "p-3"} text-lg rounded-r-lg ${
      //         loading
      //           ? "bg-blue-500 text-white cursor-not-allowed"
      //           : "bg-blue-600 text-white hover:bg-blue-700"
      //       }`}
      //       disabled={loading}
      //       onClick={() => handleSearch(q ?? "")}
      //     >
      //       {loading ? <Spinner size={"sm"} /> : <HiOutlineSearch />}
      //     </button>
      //   </div>
      // }
    >
      <AddButton onClick={() => navigate("create")} />
      <Table>
        <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-blue-100 dark:bg-blue-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3">#</th>
              <th className="px-6 py-3">Title</th>
              <th className="px-6 py-3">Limit Peserta</th>
              <th className="px-6 py-3">Author</th>
              <th className="px-6 py-3">Date</th>
              <th className="px-6 py-3">Media Layanan</th>
              <th className="px-6 py-3">Harga Sertifikat</th>
              <th className="px-6 py-3">Link</th>
              <th className="px-6 py-3 text-center">Opsi</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={9} className="text-center py-3">
                  Loading...
                </td>
              </tr>
            ) : (
              <>
                {webinars?.data.length === 0 ? (
                  <tr>
                    <td colSpan={9} className="text-center py-3">
                      Tidak ada data ditemukan!
                    </td>
                  </tr>
                ) : (
                  <>
                    {webinars?.data.map((item, key) => (
                      <tr
                        key={key}
                        className="hover:bg-blue-100 dark:hover:bg-gray-800 transition-all"
                      >
                        <td className="px-6 py-3">
                          {(
                            key +
                            1 +
                            webinars.per_page * (webinars.current_page - 1)
                          ).toString()}
                        </td>
                        <td className="px-6 py-3">{item.title ?? ""}</td>
                        <td className="px-6 py-3">
                          {item.limit_peserta?.toString() ?? ""}
                        </td>
                        <td className="px-6 py-3">{item.author ?? ""}</td>
                        <td className="px-6 py-3">{item.date ?? ""}</td>
                        <td className="px-6 py-3">
                          {item.media_layanan ?? ""}
                        </td>
                        <td className="px-6 py-3">
                          {item.harga_sertifikat ?? ""}
                        </td>
                        <td className="px-6 py-3">{item.link ?? ""}</td>
                        <td className="px-6 py-3 text-center">
                          <div className="flex justify-center gap-4">
                            {/* Delete Button */}
                            <button
                              className="text-red-600 hover:text-red-800 transition duration-300"
                              onClick={() => {
                                setSelected(item);
                                setModalDelete(true);
                              }}
                            >
                              Delete
                            </button>
                            {/* Edit Button */}
                            <button
                              className="text-blue-600 hover:text-blue-800 transition duration-300"
                              onClick={() => navigate(`${item.id}`)}
                            >
                              Edit
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </Table>

      <Pagination
        currentPage={webinars?.current_page ?? 1}
        totalPage={webinars?.last_page ?? 1}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />

      <ModalDeleteConfirmation
        isOpen={modalDelete}
        close={() => setModalDelete(false)}
        name={selected?.title ?? ""}
        loading={loadingSubmit}
        action={handleDelete}
      />
    </Layout>
  );
};

export default Webinar;
