import { create } from "zustand";
import { PaginationType } from "../types/pagination";
import { CertificateType } from "../types/certificate";

type Store = {
  GetCertificates: PaginationType<CertificateType> | null;
  setCertificates: (data: PaginationType<CertificateType>) => void;
  detail: CertificateType | null;
  setDetail: (data: CertificateType | null) => void;
};

export const useCertificates = create<Store>()((set) => ({
  GetCertificates: null,
  setCertificates: (data: PaginationType<CertificateType>) =>
    set((state) => ({
      GetCertificates: data,
    })),
  detail: null,
  setDetail: (data: CertificateType | null) =>
    set((state) => ({
      detail: data,
    })),
}));
