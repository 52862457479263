import React, { useEffect, useState } from "react";
import Layout from "../../layout.tsx/app";
import { getData } from "../../../api/get-data";
import { HiOutlineSearch, HiX } from "react-icons/hi";
import { Spinner } from "flowbite-react";
import AddButton from "../../../components/buttons/add";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/buttons";
import ModalDeleteConfirmation from "../../../components/modal/delete-confirmation";
import BaseModal from "../../../components/modal/base";
import { FormSelectAsync } from "../../../components/forms/input-select";
import Pagination from "../../../components/tables/pagination";
import Table from "../../../components/tables/base";
import { FormInput } from "../../../components/forms/input";
import { AssessmentProductType } from "../../../types/assessmentProduct";
import { moduleType } from "../../../types/module";
import { request } from "../../../api/config";
import { Pencil, Trash } from "@phosphor-icons/react";
import { useAlert } from "../../../stores/alert";
import moment from "moment";
import { useAssessmentProduct } from "../../../stores/assessmentProduct";

type FormValues = {
  name: string;
  description: string;
  modul_id: moduleType;
  image: FileList | null;
};

type ErrorForm = {
  name: [] | null;
  description: [] | null;
  modul_id: [] | null;
  image: [] | null;
};

const IndexAssessmentGroup = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [q, setQ] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<"create" | "edit" | undefined>(
    undefined
  );
  const [errors, setErrors] = useState<ErrorForm | null>(null);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<AssessmentProductType | null>(null);
  const { setValue, reset, handleSubmit, control } = useForm<FormValues>();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { setassessmentProduct, getassessmentProduct } = useAssessmentProduct();
  const { setMessage } = useAlert();

  const getAssessment = async (
    search?: string,
    searchMode: boolean = false
  ) => {
    setLoading(true);
    try {
      const data = await getData(
        "/assessment-product-group",
        page,
        search,
        searchMode
      );
      return data;
    } catch {}
  };

  const selectModules = async (inputValue: string) => {
    const { data } = await request.get(
      "/assessment-product/get-moduls?q=staff&page&offset"
    );
    return data.data.data;
  };

  const handleSearch = async (input: string | undefined) => {
    setQ(input);
    const data = await getAssessment(input ?? "", true);
    setassessmentProduct(data);
    setLoading(false);
  };

  const handleNext = () => {
    if (page === getassessmentProduct?.last_page) {
      return;
    }

    setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page === 1) {
      return;
    }

    setPage(page - 1);
  };

  const handleSave = handleSubmit(async (data) => {
    setLoadingSubmit(true);
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      if (modalMode === "create") {
        await request.post("/assessment-product-group", formData);
      } else {
        await request.put(
          `/assessment-product-group/${selected?.id}/update`,
          formData
        );
      }
      setModalAdd(false);
      setModalMode(undefined);
      setMessage("Assessment saved!", "success");
    } catch (err: any) {
      setErrors(err.response.data.errors);
      console.log(err);
    }
    setErrors(null);
    setLoadingSubmit(false);
  });

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);

      // Convert File to FileList
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      setValue("image", dataTransfer.files);
    } else {
      setImagePreview(null);
      setValue("image", null);
    }
  };

  const handleFormEdit = (item: AssessmentProductType) => {
    setSelected(item);
    setModalMode("edit");
    setValue("name", item.name ?? "");
    setValue("description", item.description ?? "");

    setImagePreview(item.image ?? null);
    setModalAdd(true);
  };

  const handleDelete = async () => {
    setLoadingSubmit(true);
    try {
      await request.delete(`/assessment-product-group/${selected?.id}/delete`);
      setSelected(null);
      setModalDelete(false);
      setMessage("Assessment deleted", "success");
    } catch (err: any) {
      setErrors(err.response.data.errors);
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    Promise.all([getAssessment()]).then((res) => {
      setassessmentProduct(res[0]);
      setLoading(false);
    });
  }, [page, loadingSubmit]);

  return (
    <Layout
      withPageTitle
      title="Assessment Group"
      // pageTitleContent={
      //   <div className="flex items-center">
      //     <input
      //       type="text"
      //       className="rounded-l-lg border-gray-300"
      //       placeholder={"Cari disini..."}
      //       onChange={(e) => setQ(e.target.value)}
      //       disabled={loading}
      //       value={q}
      //     />
      //     {q && (
      //       <button
      //         onClick={() => handleSearch("")}
      //         className="py-3 px-2 border border-red-600 bg-red-600 text-white"
      //       >
      //         <HiX />
      //       </button>
      //     )}
      //     <button
      //       className={`${loading ? "py-2 px-3" : "p-3"} text-lg rounded-r-lg ${loading
      //         ? "bg-blue-500 text-white cursor-not-allowed"
      //         : "bg-blue-600 text-white hover:bg-blue-700"
      //         }`}
      //       disabled={loading}
      //       onClick={() => handleSearch(q ?? "")}
      //     >
      //       {loading ? <Spinner size={"sm"} /> : <HiOutlineSearch />}
      //     </button>
      //   </div>
      // }
    >
      <AddButton
        onClick={() => {
          setModalAdd(true);
          setModalMode("create");
          reset();
        }}
      />
      <Table>
        <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-blue-100 dark:bg-blue-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3">#</th>
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3">Description</th>
              <th className="px-6 py-3 text-center">Opsi</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={4} className="text-center py-3">
                  Loading...
                </td>
              </tr>
            ) : (
              <>
                {getassessmentProduct?.data.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center py-3">
                      Tidak ada data ditemukan!
                    </td>
                  </tr>
                ) : (
                  <>
                    {getassessmentProduct?.data.map((item, key) => (
                      <tr
                        key={key}
                        className="hover:bg-blue-100 dark:hover:bg-gray-800 transition-all"
                      >
                        <td className="px-6 py-3">
                          {(
                            key +
                            1 +
                            getassessmentProduct.per_page *
                              (getassessmentProduct.current_page - 1)
                          ).toString()}
                        </td>
                        <td className="px-6 py-3">{item.name}</td>
                        <td className="px-6 py-3">{item.description ?? ""}</td>
                        <td className="px-6 py-3 text-center">
                          <div className="flex justify-center gap-4">
                            {/* Delete Button */}
                            <button
                              className="text-red-600 hover:text-red-800 transition duration-300"
                              onClick={() => {
                                setSelected(item);
                                setModalDelete(true);
                              }}
                            >
                              Delete
                            </button>
                            {/* Edit Button */}
                            <button
                              className="text-blue-600 hover:text-blue-800 transition duration-300"
                              onClick={() => handleFormEdit(item)}
                            >
                              Edit
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </Table>

      <Pagination
        currentPage={getassessmentProduct?.current_page ?? 1}
        totalPage={getassessmentProduct?.last_page ?? 1}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />

      <BaseModal
        title={
          modalMode === "create"
            ? "Tambah Assessment Product"
            : "Edit Assessment Product"
        }
        isOpen={modalAdd}
        close={() => setModalAdd(false)}
      >
        <form>
          <FormInput
            name="name"
            control={control}
            label="Nama"
            error={errors?.name}
          />
          <FormInput
            name="description"
            control={control}
            label="Description"
            error={errors?.description}
          />
          <div className="mt-3 flex items-center justify-end">
            <Button className="px-8" onClick={handleSave}>
              {loadingSubmit ? <Spinner /> : "Simpan"}
            </Button>
          </div>
        </form>
      </BaseModal>

      <ModalDeleteConfirmation
        isOpen={modalDelete}
        close={() => setModalDelete(false)}
        name={selected?.name ?? ""}
        loading={loadingSubmit}
        action={handleDelete}
      />
    </Layout>
  );
};

export default IndexAssessmentGroup;
