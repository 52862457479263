import { useEffect, useState, ChangeEvent, useRef } from "react";
import Layout from "../../layout.tsx/app";
import { getData } from "../../../api/get-data";
import { HiOutlineSearch, HiTrash, HiX } from "react-icons/hi";
import { Spinner } from "flowbite-react";
import AddButton from "../../../components/buttons/add";
import ModalDeleteConfirmation from "../../../components/modal/delete-confirmation";
import Pagination from "../../../components/tables/pagination";
import Table from "../../../components/tables/base";
import { ArticleType } from "../../../types/articles";
import { request } from "../../../api/config";
import { Key, Pencil, Trash } from "@phosphor-icons/react";
import { useAlert } from "../../../stores/alert";
import { useArticles } from "../../../stores/articles";
import { useNavigate } from "react-router-dom";

type ErrorForm = {
  title: [] | null;
  author: [] | null;
  categories_id: [] | null;
  date: [] | null;
  image: [] | null;
  link: [] | null;
  categories: [] | null;
};

const IndexArticle = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [q, setQ] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [errors, setErrors] = useState<ErrorForm | null>(null);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<ArticleType | null>(null);
  const { setArticles, GetArticles } = useArticles();
  const { setMessage } = useAlert();
  const navigate = useNavigate();

  // const GetAllArticle = async (
  //   search?: string,
  //   searchMode: boolean = false
  // ) => {
  //   setLoading(true);
  //   try {
  //     const data = await getData("/artikel", page, search, searchMode);
  //     return data;
  //   } catch {}
  // };

  const GetAllArticle = async (
    search?: string,
    searchMode: boolean = false
  ) => {
    setLoading(true);
    try {
      const url = searchMode && search ? `/artikel?name=${search}` : `/artikel`;
      const data = await getData(url, page);
      setLoading(false); // Move to ensure loading stops regardless
      return data;
    } catch (error) {
      console.error("Error fetching articles:", error);
      setLoading(false);
      return null; // Explicitly return null to handle errors more gracefully
    }
  };

  // const handleSearch = async (input: string | undefined) => {
  //   setQ(input);
  //   const data = await GetAllArticle(input ?? "", true);
  //   setArticles(data);
  //   setLoading(false);
  // };

  const handleSearch = async (input: string | undefined) => {
    setLoading(true);
    try {
      const data = await GetAllArticle(input ?? "", true);
      setArticles(data);
    } finally {
      setLoading(false); // Ensures loading stops even if an error occurs
    }
  };

  const handleNext = () => {
    if (page === GetArticles?.last_page) {
      return;
    }

    setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page === 1) {
      return;
    }

    setPage(page - 1);
  };

  const handleDelete = async () => {
    setLoadingSubmit(true);
    try {
      await request.delete(`/artikel/${selected?.id}`);
      setSelected(null);
      setModalDelete(false);
      setMessage("Article deleted", "success");
    } catch (err: any) {
      setErrors(err.response.data.errors);
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    Promise.all([GetAllArticle()]).then((res) => {
      setArticles(res[0]);
      setLoading(false);
    });
  }, [page, loadingSubmit]);

  return (
    <Layout
      withPageTitle
      title="Article Management"
      pageTitleContent={
        <div className="flex items-center">
          <input
            type="text"
            className="rounded-l-lg border-gray-300 w-[320px]"
            placeholder={"Cari Berdasarkan Title atau Category ....."}
            onChange={(e) => setQ(e.target.value)}
            disabled={loading}
            value={q}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(q);
              }
            }}
          />

          {q && (
            <button
              onClick={() => {
                setQ(""); // Reset pencarian
                handleSearch(""); // Jalankan pencarian dengan query kosong
              }}
              className="py-3 px-2 border border-red-600 bg-red-600 text-white"
            >
              <HiX />
            </button>
          )}

          <button
            className={`${loading ? "py-2 px-3" : "p-3"} text-lg rounded-r-lg ${
              loading
                ? "bg-blue-500 text-white cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
            disabled={loading || !q} // Disable jika tidak ada query pencarian
            onClick={() => q && handleSearch(q)}
          >
            {loading ? <Spinner size={"sm"} /> : <HiOutlineSearch />}
          </button>
        </div>
      }
    >
      <AddButton onClick={() => navigate("create")} />
      <Table>
        <table className="min-w-full table-fixed text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-blue-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3 text-left w-12">#</th>
              <th className="px-6 py-3 text-left w-1/5">Title</th>
              <th className="px-6 py-3 text-left w-1/3">Author</th>
              <th className="px-6 py-3 text-left w-1/6">Category</th>
              <th className="px-6 py-3 text-left w-1/8">Sub Category</th>{" "}
              {/* Adjusted */}
              <th className="px-6 py-3 text-left w-1/6">Date</th>
              <th className="px-6 py-3 text-left w-1/8">Image</th>
              <th className="px-6 py-3 text-center w-1/6">Opsi</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {loading ? (
              <Table.TrLoading cols={8} rows={4} />
            ) : (
              <>
                {GetArticles?.data.length === 0 ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center py-3 text-gray-600 dark:text-gray-400"
                    >
                      Tidak ada data ditemukan!
                    </td>
                  </tr>
                ) : (
                  <>
                    {GetArticles?.data.map((item, key) => (
                      <tr
                        key={key}
                        className="hover:bg-blue-100 dark:hover:bg-gray-800 transition-all"
                      >
                        <td className="px-6 py-3">
                          {key +
                            1 +
                            GetArticles.per_page *
                              (GetArticles.current_page - 1)}
                        </td>
                        <td className="px-6 py-3">{item.title ?? "-"}</td>
                        <td className="px-6 py-3">{item.author ?? "-"}</td>
                        <td className="px-6 py-3">
                          {item.categories_id?.name ?? "-"}
                        </td>
                        <td className="px-6 py-3">
                          <div className="flex flex-col gap-1">
                            {item.sub_categories.map((e, index) => (
                              <span
                                key={index}
                                className="bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
                              >
                                {e.name}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td className="px-6 py-3">{item.date ?? "-"}</td>
                        <td className="px-6 py-3">
                          {item.image && (
                            <img
                              src={item.image}
                              alt="Image"
                              className="h-10 w-10 object-cover rounded-full"
                            />
                          )}
                        </td>
                        <td className="px-6 py-3 text-center">
                          <div className="flex justify-center gap-3">
                            <button
                              className="text-red-600 hover:text-red-800 font-medium"
                              onClick={() => {
                                setSelected(item);
                                setModalDelete(true);
                              }}
                            >
                              Delete
                            </button>
                            <button
                              className="text-blue-600 hover:text-blue-800 font-medium"
                              onClick={() => navigate(`${item.id}`)}
                            >
                              Edit
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </Table>

      <Pagination
        currentPage={GetArticles?.current_page ?? 1}
        totalPage={GetArticles?.last_page ?? 1}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />

      <ModalDeleteConfirmation
        isOpen={modalDelete}
        close={() => setModalDelete(false)}
        name={selected?.title ?? ""}
        loading={loadingSubmit}
        action={handleDelete}
      />
    </Layout>
  );
};

export default IndexArticle;
