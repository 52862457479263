import { useEffect, useState, ChangeEvent, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout.tsx/app";
import { Spinner } from "flowbite-react";
import { useForm } from "react-hook-form";
import { useAssessmentProduct } from "../../../stores/assessmentProduct";
import { FormImage } from "../../../components/forms/input-image";
import { FormInput } from "../../../components/forms/input";
import { FormSelectAsync } from "../../../components/forms/input-select";
import { useAlert } from "../../../stores/alert";
import { request } from "../../../api/config";
import { getData } from "../../../api/get-data";
import LoadingPage from "../../layout.tsx/loading";
import { Button } from "../../../components/buttons";
import { CategoryType } from "../../../types/category";
import { SubCategoryType } from "../../../types/subcategory";
import { Module } from "../../../types/assessmentProduct";
import { Editor } from "@tinymce/tinymce-react";



type FormValues = {
    name: string;
    product_type: string;
    category: string;
    modul: Module | null;
    offline_base_price: string;
    online_base_price: string;
    homevisit_base_price: string;
    group: string;
    image: FileList | null;
    description: string;
};

type ErrorForm = {
    name: string[] | null;
    product_type: string[] | null;
    category: string[] | null;
    modul: [] | null;
    offline_base_price: string[] | null;
    online_base_price: string[] | null;
    homevisit_base_price: string[] | null;
    group: string[] | null;
    image: string[] | null;
    description: string[] | null;
};

const DetailAssessmentProduct = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [existingImage, setExistingImage] = useState<string | null>(null);
    const [errors, setErrors] = useState<ErrorForm | null>(null);
    const { id } = useParams();
    const { detail, setDetail } = useAssessmentProduct();
    const { setMessage } = useAlert();
    const { setValue, reset, handleSubmit, control } = useForm<FormValues>();
    const [previewSrc, setPreviewSrc] = useState<string | null>(null);
    const uploadInputRef = useRef<HTMLInputElement | null>(null);
    const imagePreviewRef = useRef<HTMLDivElement | null>(null);
    const [fileName, setFileName] = useState<string>("");
    const [editorContent, setEditorContent] = useState("");
    const navigate = useNavigate();


    const getDetail = async () => {
        setLoading(true);
        try {
            const data = await getData(`/assessment-product/${id}`);
            return data;
        } catch (err: any) {
            console.log(err);
        }
    };

    const selectCategory = async (inputValue: string) => {
        let params = {
            q: inputValue,
        };
        const { data } = await request.get("/categories-artikel/category", {
            params: params,
        });

        return data.data;
    };

    const selectSubCategory = async (inputValue: string) => {
        let params = {
            q: inputValue,
        };
        const { data } = await request.get("/subcategories/sub-category", {
            params: params,
        });

        return data.data;
    };

    const handleSave = handleSubmit(async (data) => {
        setLoadingSubmit(true);
        const modulId = data.modul?.id;
        try {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("product_type", data.product_type);
            formData.append("category", data.category);
            formData.append("modul", modulId?.toString() || '');
            formData.append("offline_base_price", data.offline_base_price);
            formData.append("online_base_price", data.online_base_price);
            formData.append("homevisit_base_price", data.homevisit_base_price);
            formData.append("group", data.group);
            formData.append("description", data.description);
            if (data.image && data.image.length > 0) {
                formData.append("image", data.image[0]);
            }

            // Only append the existing image if no new image has been uploaded
            if (!data.image && existingImage) {
                formData.append("existing_image_url", existingImage); // You can handle this on the backend
            }



            if (id) {
                formData.append("_method", "PUT");
                await request.post(`/assessment-product/${id}`, formData);
                setMessage("Assesment Product updated!", "success");
            } else {
                await request.post(`/assessment-product/`, formData);
                setMessage("Assesment Product created!", "success");
            }

            navigate('/assestment/product');
        } catch (err: any) {
            if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors); // Update state with errors
            }
            setMessage(err.response.data.message, "error");
        }
        setErrors(null);
        setLoadingSubmit(false);
    });


    const handleClick = () => {
        if (uploadInputRef.current) {
            uploadInputRef.current.click();
        }
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewSrc(reader.result as string);
            };
            reader.readAsDataURL(file);

            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            setValue("image", dataTransfer.files);
            setFileName(file.name);
        } else {
            setPreviewSrc(null);
            setValue("image", null);
            setFileName("");
        }
    };

    useEffect(() => {
        if (id) {
            setLoading(true);
            getDetail().then((res) => {
                setDetail(res[0]);
                setValue("name", res[0].name);
                setValue("product_type", res[0].product_type);
                setValue("category", res[0].category);
                setValue("modul", res[0].modul);
                setValue("offline_base_price", res[0].offline_base_price);
                setValue("online_base_price", res[0].online_base_price);
                setValue("homevisit_base_price", res[0].homevisit_base_price);
                setValue("group", res[0].group);
                setValue("description", res[0].description);
                if (res[0].image) {
                    setExistingImage(res[0].image);
                    setPreviewSrc(res[0].image);
                } else {
                    setExistingImage(null);
                }
                setLoading(false);
            });
        } else {
            setDetail(null);
            reset({
                name: "",
                product_type: "",
                category: "",
                modul: null,
                offline_base_price: "",
                online_base_price: "",
                homevisit_base_price: "",
                group: "",
                description: "",
                image: null,
            });
        }
    }, [id]);

    return (
        <Layout
            withPageTitle
            title={id ? 'Edit Assessment Product' : 'Create Assessment Product'}
            pageTitleContent={<></>}
        >
            <>
                {loading ? (
                    <LoadingPage />
                ) : (
                    <form>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-8 md:mb-0">

                                <FormInput
                                    name="name"
                                    control={control}
                                    label="Name"
                                    error={errors?.name}
                                />
                                <FormSelectAsync
                                    label="Modul"
                                    name="modul"
                                    control={control}
                                    loadOption={selectCategory}
                                    optionLabel={(option: Module) => option.name}
                                    optionValue={(option: Module) => option.id.toString()}  // Pastikan mengembalikan string ID
                                    error={errors?.modul}
                                />
                                <FormInput
                                    name="offline_base_price"
                                    type="number"
                                    control={control}
                                    label="Offline Base Price"
                                    error={errors?.offline_base_price}
                                />
                                <FormInput
                                    name="online_base_price"
                                    type="number"
                                    control={control}
                                    label="Online Base Price"
                                    error={errors?.online_base_price}
                                />
                                <FormInput
                                    name="homevisit_base_price"
                                    type="number"
                                    control={control}
                                    label="Home Visit Base Price"
                                    error={errors?.homevisit_base_price}
                                />
                                <FormInput
                                    name="group"
                                    type="text"
                                    control={control}
                                    label="Group"
                                    error={errors?.group}
                                />
                            </div>
                            <div className="-full md:w-1/2 px-3">
                                <div className="max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden items-center">
                                    <div className="px-4 py-6">
                                        <div
                                            id="image-preview"
                                            className="max-w-sm p-6 mb-4 bg-gray-100 border-dashed border-2 border-gray-400 rounded-lg items-center mx-auto text-center cursor-pointer"
                                            ref={imagePreviewRef}
                                        >
                                            <input
                                                id="upload"
                                                type="file"
                                                className="hidden"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                                ref={uploadInputRef}
                                            />
                                            {previewSrc ? (
                                                <img src={previewSrc} className="max-h-48 rounded-lg mx-auto" alt="Image preview" />
                                            ) : (
                                                <label htmlFor="upload" className="cursor-pointer">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-8 h-8 text-gray-700 mx-auto mb-4"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M3 16.5v2.25A2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                                                        />
                                                    </svg>
                                                    <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-700">Upload picture</h5>
                                                    <p className="font-normal text-sm text-gray-400 md:px-6">
                                                        Choose photo size should be less than <b className="text-gray-600">2mb</b>
                                                    </p>
                                                    <p className="font-normal text-sm text-gray-400 md:px-6">
                                                        and should be in <b className="text-gray-600">JPG, PNG</b> format.
                                                    </p>
                                                    <span id="filename" className="text-gray-500 bg-gray-200 z-50">
                                                        {fileName}
                                                    </span>
                                                </label>
                                            )}
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <div className="w-full">
                                                <label className="w-full text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 flex items-center justify-center mr-2 mb-2 cursor-pointer">
                                                    <span className="text-center ml-2" onClick={handleClick}>
                                                        Upload
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="mt-3 flex items-center justify-end">
                            <Button className="px-8" onClick={handleSave}>
                                {loadingSubmit ? <Spinner /> : (id ? "Update" : "Create")}
                            </Button>
                        </div>
                    </form>
                )}
            </>
        </Layout>
    );
};

export default DetailAssessmentProduct;
