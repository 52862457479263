import { create } from "zustand";
import { PaginationType } from "../types/pagination";
import { AssessmentProductType } from "../types/assessmentProduct";


type Store = {
    getassessmentProduct: PaginationType<AssessmentProductType> | null;
    setassessmentProduct: (data: PaginationType<AssessmentProductType>) => void;
    detail: AssessmentProductType | null;
    setDetail: (data: AssessmentProductType | null) => void;
};

export const useAssessmentProduct = create<Store>()((set) => ({
    getassessmentProduct: null,
    setassessmentProduct: (data: PaginationType<AssessmentProductType>) =>
        set((state) => ({
            getassessmentProduct: data,
        })),
    detail: null,
    setDetail: (data: AssessmentProductType | null) =>
        set((state) => ({
            detail: data,
        })),
}));
