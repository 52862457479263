import { useEffect, useState } from "react";
import Layout from "../../layout.tsx/app";
import { getData } from "../../../api/get-data";
import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineSearch, HiTrash, HiX } from "react-icons/hi";
import { Spinner } from "flowbite-react";
import AddButton from "../../../components/buttons/add";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../../components/buttons";
import ModalDeleteConfirmation from "../../../components/modal/delete-confirmation";
import Pagination from "../../../components/tables/pagination";
import Table from "../../../components/tables/base";
import { CounselingProductType } from "../../../types/counselingProduct";
import { request } from "../../../api/config";
import { Pencil, Trash } from "@phosphor-icons/react";
import { useAlert } from "../../../stores/alert";
import moment from "moment";
import { useCounselingProduct } from "../../../stores/counselingProduct";

const Counseling = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [q, setQ] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<CounselingProductType | null>(null);
  const { setCounselingProduct, GetcounselingProduct } = useCounselingProduct();
  const navigate = useNavigate();
  const { setMessage } = useAlert();

  const getCounseling = async (
    search?: string,
    searchMode: boolean = false
  ) => {
    setLoading(true);
    try {
      const data = await getData(
        "/counseling-products",
        page,
        search,
        searchMode
      );
      return data;
    } catch {}
  };

  const handleSearch = async (input: string | undefined) => {
    setQ(input);
    const data = await getCounseling(input ?? "", true);
    setCounselingProduct(data);
    setLoading(false);
  };

  const handleNext = () => {
    if (page === GetcounselingProduct?.last_page) {
      return;
    }

    setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page === 1) {
      return;
    }

    setPage(page - 1);
  };

  const handleDelete = async () => {
    setLoadingSubmit(true);
    try {
      await request.delete(`/counseling-products/${selected?.id}`);
      setSelected(null);
      setModalDelete(false);
      setMessage("Counseling Product deleted", "success");
    } catch (err: any) {
      setMessage(err.response.data.errors, "error");
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    Promise.all([getCounseling()]).then((res) => {
      setCounselingProduct(res[0]);
      setLoading(false);
    });
  }, [page, loadingSubmit]);

  return (
    <Layout
      withPageTitle
      title="Counseling Product Management"
      // pageTitleContent={
      //   <div className="flex items-center">
      //     <input
      //       type="text"
      //       className="rounded-l-lg border-gray-300"
      //       placeholder={"Cari disini..."}
      //       onChange={(e) => setQ(e.target.value)}
      //       disabled={loading}
      //       value={q}
      //     />
      //     {q && (
      //       <button
      //         onClick={() => handleSearch("")}
      //         className="py-3 px-2 border border-red-600 bg-red-600 text-white"
      //       >
      //         <HiX />
      //       </button>
      //     )}
      //     <button
      //       className={`${loading ? "py-2 px-3" : "p-3"} text-lg rounded-r-lg ${
      //         loading
      //           ? "bg-blue-500 text-white cursor-not-allowed"
      //           : "bg-blue-600 text-white hover:bg-blue-700"
      //       }`}
      //       disabled={loading}
      //       onClick={() => handleSearch(q ?? "")}
      //     >
      //       {loading ? <Spinner size={"sm"} /> : <HiOutlineSearch />}
      //     </button>
      //   </div>
      // }
    >
      <AddButton onClick={() => navigate("create")} />
      <Table>
        <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-blue-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3">#</th>
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3">Description</th>
              <th className="px-6 py-3">Slug</th>
              <th className="px-6 py-3">Notes</th>
              <th className="px-6 py-3 text-center">Opsi</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={6} className="text-center py-3 text-gray-500">
                  Loading...
                </td>
              </tr>
            ) : (
              <>
                {GetcounselingProduct?.data.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="text-center py-3 text-gray-500">
                      Tidak ada data ditemukan!
                    </td>
                  </tr>
                ) : (
                  <>
                    {GetcounselingProduct?.data.map((item, key) => (
                      <tr
                        key={key}
                        className="border-b hover:bg-blue-100 dark:hover:bg-gray-600 transition duration-200"
                      >
                        <td className="px-6 py-4">
                          {(
                            key +
                            1 +
                            GetcounselingProduct.per_page *
                              (GetcounselingProduct.current_page - 1)
                          ).toString()}
                        </td>
                        <td className="px-6 py-4">{item.name ?? ""}</td>
                        <td className="px-6 py-4">{item.description ?? ""}</td>
                        <td className="px-6 py-4">{item.slug ?? ""}</td>
                        <td className="px-6 py-4">{item.notes ?? ""}</td>
                        <td className="px-6 py-4 text-center">
                          <div className="flex items-center justify-center gap-4">
                            <button
                              className="text-red-600 hover:text-red-800 transition duration-300"
                              onClick={() => {
                                setSelected(item);
                                setModalDelete(true);
                              }}
                            >
                              Delete
                            </button>
                            <button
                              className="text-blue-600 hover:text-blue-800 transition duration-300"
                              onClick={() => navigate(`${item.id}`)}
                            >
                              Edit
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </Table>

      <Pagination
        currentPage={GetcounselingProduct?.current_page ?? 1}
        totalPage={GetcounselingProduct?.last_page ?? 1}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />

      <ModalDeleteConfirmation
        isOpen={modalDelete}
        close={() => setModalDelete(false)}
        name={selected?.name ?? ""}
        loading={loadingSubmit}
        action={handleDelete}
      />
    </Layout>
  );
};

export default Counseling;
