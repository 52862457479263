import { create } from "zustand";
import { PaginationType } from "../types/pagination";
import { UserPsikologType } from "../types/users";

type Store = {
  userPsikologs: PaginationType<UserPsikologType> | null;
  setUserPsikologs: (data: PaginationType<UserPsikologType>) => void;
  detail: UserPsikologType | null;
  setDetail: (data: UserPsikologType | null) => void;
};

export const useUserPsikolog = create<Store>()((set) => ({
  userPsikologs: null,
  setUserPsikologs: (data: PaginationType<UserPsikologType>) =>
    set((state) => ({
      userPsikologs: data,
    })),
    detail: null,
    setDetail: (data: UserPsikologType | null) =>
      set((state) => ({
        detail: data,
      })),
  }));
