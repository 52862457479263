import { useEffect, useState } from "react";
import Layout from "../layout.tsx/app";
import { getData } from "../../api/get-data";
import { Spinner } from "flowbite-react";
import ModalDeleteConfirmation from "../../components/modal/delete-confirmation";
import Pagination from "../../components/tables/pagination";
import AddButton from "../../components/buttons/add";
import { VoucherType } from "../../types/vouchers";
import { request } from "../../api/config";
import { Pencil, Trash } from "@phosphor-icons/react";
import { useAlert } from "../../stores/alert";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useVoucher } from "../../stores/voucher";

const IndexVoucher = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [q, setQ] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<VoucherType | null>(null);
  const { setVouchers, getVouchers } = useVoucher();
  const { setMessage } = useAlert();
  const navigate = useNavigate();

  const getAllVoucher = async (
    search?: string,
    searchMode: boolean = false
  ) => {
    setLoading(true);
    try {
      const data = await getData("/voucher", page, search, searchMode);
      setLoading(false);
      return data;
    } catch {
      setLoading(false);
    }
  };

  const handleSearch = async (input: string | undefined) => {
    setQ(input);
    const data = await getAllVoucher(input ?? "", true);
    setVouchers(data);
  };

  const handleNext = () => {
    if (page === getVouchers?.last_page) {
      return;
    }
    setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page === 1) {
      return;
    }
    setPage(page - 1);
  };

  const handleDelete = async () => {
    setLoadingSubmit(true);
    try {
      await request.delete(`/voucher/delete/${selected?.id}`);
      setSelected(null);
      setModalDelete(false);
      setMessage("Voucher deleted", "success");
    } catch (err: any) {
      setMessage(err.response.data.errors, "error");
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    Promise.all([getAllVoucher()]).then((res) => {
      setVouchers(res[0]);
      setLoading(false);
    });
  }, [page, loadingSubmit]);

  return (
    <Layout withPageTitle title="Voucher Management">
      <AddButton onClick={() => navigate("/voucher/create")} />

      {/* Table with modern Tailwind CSS */}
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-blue-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3">No</th>
              <th className="px-6 py-3">Code</th>
              <th className="px-6 py-3">Description</th>
              <th className="px-6 py-3">Amount</th>
              <th className="px-6 py-3">Expiry Date</th>
              <th className="px-6 py-3">User Type</th>
              <th className="px-6 py-3 text-center">For</th>
              <th className="px-6 py-3 text-center">Options</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={8} className="text-center py-3">
                  <Spinner size="sm" />
                </td>
              </tr>
            ) : getVouchers?.data.length === 0 ? (
              <tr>
                <td colSpan={8} className="text-center py-3">
                  No data found!
                </td>
              </tr>
            ) : (
              getVouchers?.data.map((item, key) => (
                <tr
                  key={key}
                  className="bg-white border-b hover:bg-blue-100 dark:bg-gray-900 dark:border-gray-700 dark:hover:bg-gray-800"
                >
                  <td className="px-6 py-3">
                    {key +
                      1 +
                      getVouchers.per_page * (getVouchers.current_page - 1)}
                  </td>
                  <td className="px-6 py-3">{item.code || "No code"}</td>
                  <td className="px-6 py-3">{item.description}</td>
                  <td className="px-6 py-3">{item.amount}</td>
                  <td className="px-6 py-3">
                    {moment(item.expiry_date).format("DD-MM-YYYY")}
                  </td>
                  <td className="px-6 py-3">{item.user_type}</td>
                  <td className="px-6 py-3 text-center">{item.for}</td>
                  <td className="px-6 py-3 text-center">
                    <div className="flex items-center justify-center gap-2">
                      {/* Delete button */}
                      <button
                        onClick={() => {
                          setSelected(item);
                          setModalDelete(true);
                        }}
                        className="text-red-600 text-sm hover:text-red-800 focus:outline-none focus:ring-2 focus:ring-red-500 p-2 rounded-md transition-all"
                      >
                        Delete
                      </button>

                      {/* Edit button */}
                      <button
                        onClick={() => navigate(`/voucher/${item.id}`)}
                        className="text-blue-600 text-sm hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 p-2 rounded-md transition-all"
                      >
                        Edit
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={getVouchers?.current_page ?? 1}
        totalPage={getVouchers?.last_page ?? 1}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />

      {/* Delete Modal */}
      <ModalDeleteConfirmation
        isOpen={modalDelete}
        close={() => setModalDelete(false)}
        subTitle="Code"
        name={selected?.code ?? ""}
        loading={loadingSubmit}
        action={handleDelete}
      />
    </Layout>
  );
};

export default IndexVoucher;
